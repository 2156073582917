var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    _vm._l(_vm.chaperoneRules, function (cr, i) {
      return _c(
        "v-card",
        { key: i, staticClass: "mb-4" },
        [
          _c("v-card-title", [
            _vm._v(
              "Rules for Adult/Student Ratio (Grades: " +
                _vm._s(cr ? cr.gradeLevels.join(", ") : "") +
                ")"
            ),
          ]),
          _c(
            "v-form",
            { attrs: { readonly: !_vm.canEditSettings } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-chip-group",
                            {
                              attrs: {
                                "active-class": "primary",
                                column: "",
                                multiple: "",
                              },
                              model: {
                                value: cr.gradeLevels,
                                callback: function ($$v) {
                                  _vm.$set(cr, "gradeLevels", $$v)
                                },
                                expression: "cr.gradeLevels",
                              },
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    value: "All",
                                    disabled: !_vm.canEditSettings,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleAll(i)
                                    },
                                  },
                                },
                                [_vm._v("All")]
                              ),
                              _vm._l(_vm.filteredGrades[i], function (grade) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: grade,
                                    attrs: {
                                      value: grade,
                                      disabled: !_vm.canEditSettings,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.clickGrade($event, i)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(grade) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-subheader", { staticClass: "inline" }, [
                            _vm._v("There must be"),
                          ]),
                          _c("v-text-field", {
                            staticClass: "inline text-field",
                            attrs: { outlined: "", type: "number", min: "0" },
                            model: {
                              value: cr.adultsPerStudents,
                              callback: function ($$v) {
                                _vm.$set(cr, "adultsPerStudents", $$v)
                              },
                              expression: "cr.adultsPerStudents",
                            },
                          }),
                          _c("v-subheader", { staticClass: "inline" }, [
                            _vm._v("adult(s) for"),
                          ]),
                          _c("v-text-field", {
                            staticClass: "inline text-field",
                            attrs: { outlined: "", type: "number", min: "0" },
                            model: {
                              value: cr.numStudents,
                              callback: function ($$v) {
                                _vm.$set(cr, "numStudents", $$v)
                              },
                              expression: "cr.numStudents",
                            },
                          }),
                          _c("v-subheader", { staticClass: "inline" }, [
                            _vm._v("or more students."),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-subheader", { staticClass: "inline" }, [
                            _vm._v("There must be"),
                          ]),
                          _c("v-text-field", {
                            staticClass: "inline text-field",
                            attrs: { outlined: "", type: "number", min: "0" },
                            model: {
                              value: cr.adultsPerAdditional,
                              callback: function ($$v) {
                                _vm.$set(cr, "adultsPerAdditional", $$v)
                              },
                              expression: "cr.adultsPerAdditional",
                            },
                          }),
                          _c("v-subheader", { staticClass: "inline" }, [
                            _vm._v("adult(s) for every additional"),
                          ]),
                          _c("v-text-field", {
                            staticClass: "inline text-field",
                            attrs: { outlined: "", type: "number", min: "0" },
                            model: {
                              value: cr.additionalStudents,
                              callback: function ($$v) {
                                _vm.$set(cr, "additionalStudents", $$v)
                              },
                              expression: "cr.additionalStudents",
                            },
                          }),
                          _c("v-subheader", { staticClass: "inline" }, [
                            _vm._v("students."),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              " Do you require at least one female chaperone for female students and at least one male chaperone for male students? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "ml-4",
                              attrs: { row: "" },
                              model: {
                                value: cr.requireSameGender,
                                callback: function ($$v) {
                                  _vm.$set(cr, "requireSameGender", $$v)
                                },
                                expression: "cr.requireSameGender",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }